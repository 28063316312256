import React, { useState } from 'react'

import Image, { StaticImageData } from 'next/image'

type Props = {
  selected?: boolean
  name?: string
  image?: StaticImageData
  onClick?: () => void
}

export default function ModeSelectorItem({
  selected,
  image,
  name,
  onClick,
}: Props) {
  const [isHovered, setIsHovered] = useState(false)
  const animated = isHovered || selected

  function borderStyle() {
    return animated
      ? 'outline-2 outline-kassadin-300'
      : 'outline-1 outline-[#242B39]'
  }

  function backgroundStyle() {
    return animated ? 'bg-kassadin-800' : 'bg-fizz-800'
  }

  return (
    <div
      className={`rounded-xl flex-1 outline ${backgroundStyle()} ${borderStyle()} h-26 cursor-pointer flex flex-col items-center transition-all`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => {
        onClick && onClick()
      }}
    >
      <div
        className={`transition-all relative w-[75px] h-[70px] shrink-0 ${animated && '-translate-y-[12px] scale-110'}`}
      >
        <Image
          src={image ?? process.env.NEXT_PUBLIC_DEFAULT_CUSTOMER_AVATAR_URL!}
          fill={true}
          className={'object-contain'}
          alt={'Warning icon'}
        />
      </div>
      <p
        className={`text-body-bold ${animated ? 'text-white' : 'text-fizz-100'} -mt-1.5`}
      >
        {name}
      </p>
    </div>
  )
}
