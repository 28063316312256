import React from 'react'
import { useRecoilState } from 'recoil'

import ModeSelectorItem from './ModeSelectorItem'
import useProductMode, { selectedProductModeState } from './useProductMode'

export default function ModeSelector() {
  const [selectedProductMode, setSelectedProductMode] = useRecoilState(
    selectedProductModeState,
  )
  const { modes } = useProductMode()

  return (
    <div className={'flex flex-row items-center gap-4'}>
      {modes.map((mode) => {
        return (
          <ModeSelectorItem
            key={mode.name}
            name={mode.name}
            image={mode.image}
            onClick={() => {
              setSelectedProductMode(mode)
            }}
            selected={selectedProductMode === mode}
          />
        )
      })}
    </div>
  )
}
